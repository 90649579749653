import { ServiceFactory } from '@/services/ServiceFactory'
const _settingsService = ServiceFactory.get('SettingsService')

export default {
  name: 'FormBarProfile',
  data () {
    return {
      zoom: 4,
      location: {
        latitude: null,
        longitude: null
      },
      errorCoordinates: null,
      schedules: [
        '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00',
        '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30',
        '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '23:59'
      ],
      defaultSchedules: [
        {
          'day': 'Lunes',
          'opensAt': '',
          'closesAt': '',
          'dayNumber': 0
        },
        {
          'day': 'Martes',
          'opensAt': '',
          'closesAt': '',
          'dayNumber': 1
        },
        {
          'day': 'Miércoles',
          'opensAt': '',
          'closesAt': '',
          'dayNumber': 2
        },
        {
          'day': 'Jueves',
          'opensAt': '',
          'closesAt': '',
          'dayNumber': 3
        },
        {
          'day': 'Viernes',
          'opensAt': '',
          'closesAt': '',
          'dayNumber': 4
        },
        {
          'day': 'Sábado',
          'opensAt': '',
          'closesAt': '',
          'dayNumber': 5
        },
        {
          'day': 'Domingo',
          'opensAt': '',
          'closesAt': '',
          'dayNumber': 6
        }
      ],
      settings: null,
      postData: {
        'barName': '',
        'slogan': '',
        'telephone': '',
        'maxPeopleCapacity': null,
        'workingSchedule': [],
        'freeSearchMode': false,
        'freeMusicRequest': false,
        'address': '',
        'location': {
          'latitude': 0,
          'longitude': 0
        },
        'musicAutoplay': true,
        'socialMedia': [
          {
            'url': '',
            'name': 'website'
          },
          {
            'url': '',
            'name': 'facebook'
          },
          {
            'url': '',
            'name': 'instagram'
          }
        ]
      }
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      try {
        const { settings } = await _settingsService.get()
        const { name, slogan, workingSchedule, address, maxPeopleCapacity, telephone, socialMedia, location } = settings
        this.postData = {
          name,
          slogan,
          telephone,
          maxPeopleCapacity,
          workingSchedule,
          freeSearchMode: false,
          freeMusicRequest: false,
          address,
          musicAutoplay: true,
          socialMedia,
          location
        }
        var hash = {}
        const workingScheduleFilter = await workingSchedule.filter((v, k) => {
          var exists = !hash[v.dayNumber]
          hash[v.dayNumber] = true
          return exists
        })
        await this.postData.workingSchedule.map((v, k) => {
          this.defaultSchedules[v.dayNumber] = v
        })
        await this.updateWorkingSchedule(workingScheduleFilter)
        if (telephone === 0) {
          this.postData.telephone = null
        }
        if (maxPeopleCapacity === 0) {
          this.postData.maxPeopleCapacity = null
        }
        if (location.latitude === 0 || location.longitude === 0) {
          this.postData.location.latitude = null
          this.postData.location.longitude = null
        }
        if (this.postData.location.latitude && this.postData.location.longitude) {
          this.location = {
            latitude: this.postData.location.latitude,
            longitude: this.postData.location.longitude
          }
          this.zoom = 15
        } else {
          // default Bolivia
          this.location = {
            latitude: -17.1790134366781,
            longitude: -66.1422156379736
          }
          this.zoom = 4
        }
      } catch (error) {
        this.$notify({
          title: 'Error',
          type: 'error',
          text: error.msg
        })
      }
    },
    resetMarkerMap () {
      this.postData = {
        latitud: null,
        longitud: null
      }
      this.location = {
        latitud: -17.1790134366781,
        longitud: -66.1422156379736
      }
      this.zoom = 4
    },
    async updateMarkerMap () {
      const isValidLongitud = await this.$refs['longitude'].validate()
      const isValidLatitud = await this.$refs['latitude'].validate()
      if (isValidLongitud.valid && isValidLatitud.valid) {
        this.location.latitude = this.postData.location.latitude
        this.location.longitude = this.postData.location.longitude
        this.errorCoordinates = null
      } else {
        this.errorCoordinates = 'Ingrese coordenas validas para actualizar el mapa ó arrastre el marcador'
      }
      if (!this.$route.params.id) {
        this.zoom = 6
      }
    },
    dragEndEvent (e) {
      this.location.latitude = e.latitude
      this.location.longitude = e.longitude

      this.postData.location.latitude = e.latitude
      this.postData.location.longitude = e.longitude
      this.zoom = 6
      this.errorCoordinates = null
    },
    async save () {
      this.postData.workingSchedule.sort((a, b) => { return a.dayNumber - b.dayNumber })
      this.postData.workingSchedule = this.postData.workingSchedule.filter((item) => ((item.closesAt !== null && item.closesAt !== '') && (item.opensAt !== null && item.opensAt !== '')))
      if (Number(this.postData.telephone) <= 0) {
        this.postData.telephone = 0
      } else {
        this.postData.telephone = Number(this.postData.telephone)
      }
      if (Number(this.postData.maxPeopleCapacity) <= 0) {
        this.postData.maxPeopleCapacity = 0
      } else {
        this.postData.maxPeopleCapacity = Number(this.postData.maxPeopleCapacity)
      }
      if (Number(this.postData.location.latitude) === 0) {
        this.postData.location.latitude = 0
      } else {
        this.postData.location.latitude = Number(this.postData.location.latitude)
      }
      if (Number(this.postData.location.longitude) === 0) {
        this.postData.location.longitude = 0
      } else {
        this.postData.location.longitude = Number(this.postData.location.longitude)
      }
      try {
        await _settingsService.put(this.postData)
        this.$notify({
          title: 'Exito',
          type: 'success',
          text: 'Perfil de negocio, actualizado correctamente'
        })
        // this.$store.commit('settingsModule/INITIALIZE', { settings: this.postData, success: true })
        this.close()
      } catch (error) {
        this.$notify({
          title: 'Error',
          type: 'error',
          text: error.msg
        })
      }
    },
    close () {
      this.$refs.observer.reset()
      this.$router.push({ name: 'bar-profile' })
    },
    changeAt (item) {
      this.postData.workingSchedule = this.postData.workingSchedule.map((v, k) => {
        if (item.dayNumber === v.dayNumber) {
          return item
        } else {
          return v
        }
      })
    },
    async updateWorkingSchedule (workingScheduleItem) {
      try {
        const { settings } = await _settingsService.put({
          workingSchedule: workingScheduleItem
        })
        this.postData.workingSchedule = await settings.workingSchedule
      } catch (error) {
        this.$notify({
          title: 'Error',
          type: 'error',
          text: error.msg
        })
      }
    }
  }
}
